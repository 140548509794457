<template functional>
  <svg
    width="80"
    height="100"
    viewBox="0 0 80 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="vertical-align: top"
  >
    <rect
      x="2"
      y="2"
      width="76"
      height="96"
      rx="2"
      stroke="#001965"
      stroke-width="4"
    />
    <line
      x1="30"
      y1="12"
      x2="50"
      y2="12"
      stroke="#001965"
      stroke-width="4"
      stroke-linecap="round"
    />
    <circle cx="40" cy="89" r="1.5" stroke="#001965" stroke-width="3" />
    <path
      d="M29.1512 57.264H26.3992V62H21.6952V57.264H11.9352V53.904L21.9512 39.152H26.3992V53.5201H29.1512V57.264ZM21.6952 53.5201V49.648C21.6952 49.1574 21.7059 48.56 21.7272 47.856C21.7486 47.152 21.7699 46.5014 21.7912 45.904C21.8339 45.2854 21.8659 44.88 21.8872 44.688H21.7592C21.5672 45.1147 21.3646 45.5307 21.1512 45.936C20.9379 46.3414 20.6819 46.7574 20.3832 47.184L16.1912 53.5201H21.6952Z"
      fill="#001965"
    />
    <path
      d="M47.4637 50.576C47.4637 53.0294 47.1971 55.1307 46.6637 56.88C46.1517 58.6294 45.3091 59.9734 44.1357 60.912C42.9837 61.8507 41.4264 62.32 39.4637 62.32C36.7117 62.32 34.6957 61.2854 33.4157 59.216C32.1357 57.1254 31.4957 54.2454 31.4957 50.576C31.4957 48.1014 31.7517 45.9894 32.2637 44.24C32.7757 42.4907 33.6184 41.1467 34.7917 40.208C35.9651 39.2694 37.5224 38.8 39.4637 38.8C42.1944 38.8 44.2104 39.8347 45.5117 41.904C46.8131 43.9734 47.4637 46.864 47.4637 50.576ZM36.2957 50.576C36.2957 53.1787 36.5197 55.1414 36.9677 56.4641C37.4157 57.7654 38.2477 58.416 39.4637 58.416C40.6584 58.416 41.4904 57.7654 41.9597 56.4641C42.4291 55.1627 42.6637 53.2001 42.6637 50.576C42.6637 47.9734 42.4291 46.0107 41.9597 44.688C41.4904 43.3654 40.6584 42.704 39.4637 42.704C38.2477 42.704 37.4157 43.3654 36.9677 44.688C36.5197 46.0107 36.2957 47.9734 36.2957 50.576Z"
      fill="#001965"
    />
    <path
      d="M67.0562 57.264H64.3042V62H59.6002V57.264H49.8402V53.904L59.8562 39.152H64.3042V53.5201H67.0562V57.264ZM59.6002 53.5201V49.648C59.6002 49.1574 59.6109 48.56 59.6322 47.856C59.6536 47.152 59.6749 46.5014 59.6962 45.904C59.7389 45.2854 59.7709 44.88 59.7922 44.688H59.6642C59.4722 45.1147 59.2696 45.5307 59.0562 45.936C58.8429 46.3414 58.5869 46.7574 58.2882 47.184L54.0962 53.5201H59.6002Z"
      fill="#001965"
    />
  </svg>
</template>

<script>
export default { name: 'ImgNotFound' }
</script>
