
import Vue from 'vue'
import ImgNotFound from '@/components/images/ImgNotFound.vue'

export default Vue.extend({
  name: 'NotFound',
  props: { message: { type: String, default: '' } },
  components: { ImgNotFound },
  computed: {
    formattedMessage() {
      return this.message || '404 Page not found'
    },
  },
  mounted() {
    Vue.$tracking.error('Not found page', new Error('NotFound.vue'), {
      fullPath: this.$route.fullPath,
      formattedMessage: this.formattedMessage,
    })
  },
})
